<template>
<div>
  <div class="vx-row">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('TodoList')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'todo-list' })">
            Kembali
          </vs-button>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/4" v-for="(cell, i) in cells" :key="i">
            <div class="mt-5">
              <h6 class="font-bold">{{ cell.header }}</h6>
              <p v-if="cell.format">
                <span v-html="cell.format(item[cell.field])" />
              </p>
              <p v-else>
                {{ item[cell.field] }}
              </p>
            </div>
          </div>
        </div>


      </vx-card>

    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TodoListDetail",
  data: () => ({
    item: {},
  }),
  computed: {
    cells () {
      return [
        {
          header: this.$i18n.t('Date'),
          field: 'date'
        },
        {
          header: this.$i18n.t('Start'),
          field: 'start',
          format: (item) => {
            return item + ' WIB';
          }
        },
        {
          header: this.$i18n.t('End'),
          field: 'end',
          format: (item) => {
            return item + ' WIB';
          }
        },
        {
          header: this.$i18n.t('TodoList'),
          field: 'todo_list',
          format: (item) => {
            return item.replace(/\n/g, '<br>')
          }
        },
      ]
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('todolist/detailItem', {id: this.id}).then(res => {
      this.item = res.data.values;
    });
  }
}
</script>

<style scoped>

</style>
